import React, { Component } from 'react'
import Popup from "reactjs-popup";
import '../../../containers/Leaderprofile/Leaderprofile.css'
import './Mydairies.css'
import Axios from 'axios';
import Showimage from '../../Leadersfeed/Popup/Showimage/Showimage';
export class Mydairies extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             my_dairies_details:'',
             hierarchy1:false,
             hierarchy2:false,
             hierarchy3:false,
             hierarchy4:false,
             hierarchy5:false,
             hierarchy6:false,
             hierarchy7:false,
             hierarchy8:false,
             hierarchy9:false
        }
        this.componentDidMount = this.componentDidMount.bind(this)
    }
    componentDidMount(){
    Axios.get(process.env.REACT_APP_BASE_URL+`target/targetdairies/${this.props.follower_id}/${this.props.leader_id}`).then(response=>{
            if(response.data.status === 201){
                    this.setState({
                        my_dairies_details:"no details"
                    })
            }
            else{
                this.setState({
                    my_dairies_details:response.data.Results[0],
                    hierarchy1:response.data.Results[0].hierarchy1,
                    hierarchy2:response.data.Results[0].hierarchy2,
                    hierarchy3:response.data.Results[0].hierarchy3,
                    hierarchy4:response.data.Results[0].hierarchy4,
                    hierarchy5:response.data.Results[0].hierarchy5,
                    hierarchy6:response.data.Results[0].hierarchy6,
                    hierarchy7:response.data.Results[0].hierarchy7,
                    hierarchy8:response.data.Results[0].hierarchy8,
                    hierarchy9:response.data.Results[0].hierarchy9
                })
            }
        }).catch(function(error){
console.log(error)
        })
        }
    

    render() {
        let btn;
        let hierarchy1;
        let hierarchy2;
        let hierarchy3;
        let hierarchy4;
        let hierarchy5;
        let hierarchy6;
        let hierarchy7;
        let hierarchy8;
        let hierarchy9;

if(this.state.hierarchy1){
 hierarchy1=<Popup modal trigger={
    <img src={process.env.REACT_APP_BASE_URL+`${this.state.my_dairies_details.hierarchy1}`} className="mydairies-image-left"/> }>
                                    {close => <Showimage close={close} photo = {this.state.my_dairies_details.hierarchy1} 
                                     />} 
                                   </Popup>
}
if(this.state.hierarchy2){
                  hierarchy2 =            <Popup modal trigger={
<img src={process.env.REACT_APP_BASE_URL+`${this.state.my_dairies_details.hierarchy2}`} className="mydairies-image-center"/> }>
                                    {close => <Showimage close={close} photo = {this.state.my_dairies_details.hierarchy2} 
                                     />} 
                                   </Popup>
}
if(this.state.hierarchy3){
                   hierarchy3=                <Popup modal trigger={
<img src={process.env.REACT_APP_BASE_URL+`${this.state.my_dairies_details.hierarchy3}`} className="mydairies-image-right"/> }>
                                    {close => <Showimage close={close} photo = {this.state.my_dairies_details.hierarchy3} 
                                     />} 
                                   </Popup>
}
if(this.state.hierarchy4){
hierarchy4=<Popup modal trigger={
<img src={process.env.REACT_APP_BASE_URL+`${this.state.my_dairies_details.hierarchy4}`} className="mydairies-image-left"/> }>
                                    {close => <Showimage close={close} photo = {this.state.my_dairies_details.hierarchy4} 
                                     />} 
                                   </Popup>
}
if(this.state.hierarchy5){                         
                                  hierarchy5=<Popup modal trigger={
<img src={process.env.REACT_APP_BASE_URL+`${this.state.my_dairies_details.hierarchy5}`} className="mydairies-image-center"/> }>
                                    {close => <Showimage close={close} photo = {this.state.my_dairies_details.hierarchy5} 
                                     />} 
                                   </Popup>
}
if(this.state.hierarchy6){
                             hierarchy6=      <Popup modal trigger={
<img src={process.env.REACT_APP_BASE_URL+`${this.state.my_dairies_details.hierarchy6}`} className="mydairies-image-right"/> }>
                                    {close => <Showimage close={close} photo = {this.state.my_dairies_details.hierarchy6} 
                                     />} 
                                   </Popup>
}
if(this.state.hierarchy7){
 hierarchy7=<Popup modal trigger={
<img src={process.env.REACT_APP_BASE_URL+`${this.state.my_dairies_details.hierarchy7}`} className="mydairies-image-left"/> }>
                                    {close => <Showimage close={close} photo = {this.state.my_dairies_details.hierarchy7} 
                                     />} 
                                   </Popup>
}
if(this.state.hierarchy8){
                             hierarchy8=      <Popup modal trigger={
<img src={process.env.REACT_APP_BASE_URL+`${this.state.my_dairies_details.hierarchy8}`} className="mydairies-image-center"/> }>
                                    {close => <Showimage close={close} photo = {this.state.my_dairies_details.hierarchy8} 
                                     />} 
                                   </Popup>
}
if(this.state.hierarchy9){
                                  hierarchy9= <Popup modal trigger={
<img src={process.env.REACT_APP_BASE_URL+`${this.state.my_dairies_details.hierarchy9}`} className="mydairies-image-right"/> }>
                                    {close => <Showimage close={close} photo = {this.state.my_dairies_details.hierarchy9} 
                                     />} 
                                   </Popup>
}   
        
        
        return (
            <div> 
            <div className="profile-content-info-del2 dairy_pics">

<div className="profile-content-info-header">

<div> <span className="card-heading">My Diaries </span>
</div>
</div>

{hierarchy1}{hierarchy2}{hierarchy3}
{hierarchy4}{hierarchy5}{hierarchy6}
{hierarchy7}{hierarchy8}{hierarchy9}
{btn}

</div></div>
            
        )
    }
}

export default Mydairies
