import React, { Component } from 'react'
import './Reactloader.css'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
class Reactthreedots extends Component {
  render() {
    return (
         <div className="spinner">
      <Loader
         type="ThreeDots"
         height={15}
         color="#1de8b4"
      /></div>
    )
  }
}

export default Reactthreedots
