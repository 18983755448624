import React, { Component } from "react";
import "../../Leaderprofile/Profileheader/Profileheader.css";
import axios from "axios";
import { Dropdown, Row, Col } from "react-bootstrap";
import altprofile from "../../../containers/Images/altprofile.png";
import Popup from "reactjs-popup";
import Showimage from "../../Leadersfeed/Popup/Showimage/Showimage";
import Axios from "axios";
import { toast } from "react-toastify";
class Profileheader extends Component {
  state = {
    profilephoto: "",
    display_name: "",
    selectedfile: false,
    list: "",
    profilephotoupdate: "",
    rawprofilephoto: "",
    selectfollower: "",
    target_id: "",
    type: "",
    party_details: "",
    selectfollower: "",
  };
  componentDidMount() {
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
          `myLeaders/leaderlinkslist/${this.props.leader_id}`
      )
      .then((response) => {
        this.setState({
          list: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
          `myLeaders/partylogodetails/${this.props.leader_id}`
      )
      .then((response) => {
        if (response.data.status != 201) {
          this.setState({ party_details: response.data.Results[0] });
        }
      });
  }
  handlefallow = () => {
    Axios.post(process.env.REACT_APP_BASE_URL + `Follower/followleader`, {
      s_leaderId: this.props.follower_id,
      d_leaderId: this.props.leader_id,
      type: "F",
    }).then((response) => {
      if (response.data.status == 200) {
        this.setState({
          selectfollower: "Following",
        });
        toast.success("You started to follow  this leader", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          className: "foo-bar",
        });
      }
    });
  };
  handleunfallow = () => {
    Axios.post(process.env.REACT_APP_BASE_URL + `Follower/unfollowleader`, {
      sourceId: this.props.follower_id,
      destId: this.props.leader_id,
    }).then((response) => {
      if (response.data.status == 200) {
        this.setState({
          selectfollower: "Follow",
        });
        toast.success("Unfollowed successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          className: "foo-bar",
        });
      }
    });
  };
  render() {
    console.log("this.state.party_details", this.state.party_details);
    let coverpic;
    if (this.props.profile_details.coverpage) {
      coverpic = (
        <img
          src={
            process.env.REACT_APP_BASE_URL +
            `${this.props.profile_details.coverpage}`
          }
          className="hero1 canvascoverpic" alt=""
        />
      );
    } else {
      coverpic = <div className="hero1 canvascoverpic" />;
    }
    let img;
    if (this.state.party_details.party_logo) {
      img = (
        <img
          src={
            process.env.REACT_APP_BASE_URL +
            `${this.state.party_details.party_logo}`
          }
          height="50px"
          width="50px"
          className="party-logo-profile"
          alt=""
        />
      );
    } else {
      img = (
        <img
          src={altprofile}
          height="50px"
          width="50px"
          className="party-logo-profile"
          alt=""
        />
      );
    }
    let dropdownlabel;
    let dropdwn;
    if (!this.state.selectfollower) {
      if (this.props.profile_details.relation === "follower") {
        dropdownlabel = "Following";
        dropdwn = (
          <Dropdown className="select-drop-down">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {dropdownlabel}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={this.handleunfallow}>
                Unfollow
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      } else if (this.props.profile_details.relation === "not_following") {
        dropdownlabel = "Follow";
        dropdwn = (
          <Dropdown className="select-drop-down">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {dropdownlabel}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={this.handlefallow}>Follow</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    } else if (this.state.selectfollower === "Following") {
      dropdownlabel = "Following";
      dropdwn = (
        <Dropdown className="select-drop-down">
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {dropdownlabel}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={this.handleunfallow}>
              UnFollow
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    } else if (this.state.selectfollower === "Follow") {
      dropdownlabel = "Follow";
      dropdwn = (
        <Dropdown className="select-drop-down">
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {dropdownlabel}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={this.handlefallow}>Follow</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
    const url = process.env.REACT_APP_BASE_URL + `${this.props.profilephoto}`;
    let description;
    if (
      this.state.party_details.description &&
      this.state.party_details.description !== "null"
    ) {
      description = (
        <span className="leader-description">
          {this.state.party_details.description}
        </span>
      );
    }
    return (
      <div className="hero-text-box headersectiontargetleader">
        <div>{coverpic}</div>
        <span className="select-drop-down">{dropdwn}</span>

        <div className="hero2">
          {(() => {
            if (this.props.profilephoto === null) {
              return <img src={altprofile} alt="" />;
            } else {
              return (
                <div>
                  <Popup
                    modal
                    trigger={
                      <div>
                        <img src={url} alt="" />{" "}
                      </div>
                    }
                  >
                    {(close) => (
                      <Showimage
                        close={close}
                        photo={this.props.profilephoto}
                      />
                    )}
                  </Popup>
                </div>
              );
            }
          })()}
        </div>
        <div className="hero345">
          <Row>
            <Col sm={6} className="headerrespons">
              <div>
                <span className="user-name">
                  {this.props.profile_details.display_name}
                </span>
                <div> {description}</div>
                <span>
                  <div>
                    {" "}
                    <strong>Followers:</strong>
                    <span className="folloers-count">
                      {this.state.list.followers}{" "}
                    </span>
                  </div>
                </span>{" "}
              </div>
            </Col>
            <Col md={5} sm={2}>
              {(() => {
                if (this.state.party_details.party_name) {
                  return (
                    <React.Fragment>
                      <div>
                        <div className="part_details party_details_target">
                          <span className="party-name">
                            {this.state.party_details.party_name}
                          </span>{" "}
                          <span className="hero-footer-1">{img}</span>{" "}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                }
              })()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default Profileheader;
