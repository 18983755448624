import React, { Component } from 'react'
import Logo from '../../Images/Logo.png'
import signupleftbanner from '../../Images/signupleftbanner.png'
import {Container,Row, Col} from 'react-bootstrap'
import './Verify.css'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import axios from 'axios'
import Footer from '../../../components/Footer/Footer'
const intialstate = {
    otpError:'',
}
export class Verify extends Component {
    constructor(props) {
        super(props)
        this.state = {
            intialstate,
            trackme:'',
            otpresent:false,
             otp:'',
             resentotpshow:true,
             otpsent:false
        }
        this.Verifyotp = this.Verifyotp.bind(this)
    }
    handleChange = (event) =>{
        let mob = event.target.value
        if(mob.match(/^[0-9]/) && mob.length <= 4){
            this.setState({otp:mob})
        }else if(mob.length <1){
            this.setState({otp:""})
        }
    }
    
componentDidMount() {
    if(!this.props.location.state){
        this.props.history.push('/*')
    }
}
    validate = () =>{
        let otpError;
        if(!this.state.otp){
            otpError = " Please enter OTP"
        }
        if(this.state.otp.length < 4 ){
            otpError = "Enter four digits of OTP"
        }
        if(otpError){
            this.setState({otpError})
            return false;
        }if(!this.state.otp.match(/^[0-9]{4}$/)){
            otpError = "Enter valid OTP"
            this.setState({otpError})
            return false;
        }
        return true;
    }
Verifyotp = (event) =>{
    event.preventDefault();
    const isValid = this.validate();
    if(isValid){
        this.setState(intialstate)
        axios.post(process.env.REACT_APP_BASE_URL+`Follower/verification`,{
            mobile:this.props.location.state.mobilenumber,
            otp:this.state.otp
        })
        .then((response) => {
            if(response.data.status === 200 && response.data.trackme === 1){
                toast.success('OTP Verified', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    className: 'foo-bar'
                    })
                setTimeout(() =>{
                    this.props.history.push({
                            pathname:'/userdetails',
                            state:{
                                mobilenumber:this.props.location.state.mobilenumber,
                            }
                    })
                },1000)
            } else if(response.data.trackme === 2 || response.data.trackme === 3){
                toast.success('OTP Verified', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    className: 'foo-bar'
                    })
                    setTimeout(()=>{
                        this.props.history.push({
                            pathname:'/profiledetails',
                            state:{
                            mobilenumber:this.props.location.state.mobilenumber,
                            follower_id:response.data.follower_id
                            }
                        })
                    },1000)
            }
            else if(response.data.status === 403){
                toast.error('Invalid OTP', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    className: 'foo-bar-error'
                    })
            }
            else{
                toast.error('Server error', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    className: 'foo-bar-error'
                })
            }
        }).catch(function(error)
        {
            console.log(error);
        }
        )
    }
}
resendotp = () =>{
    axios.post(process.env.REACT_APP_BASE_URL+`Follower/registration-step1`,{
        mobile:this.props.location.state.mobilenumber
    }).then(response=>{
        this.setState({otpresent:"OTP resent successfully"})
        setTimeout(()=>{
            this.setState({otpresent:false})
        },10000)
    })
}
    render() {
        let otpvalue= this.state.otp
        let resenttext;
        let resentotpshow;
        if(this.state.otpresent){
            resenttext = <span className="resend-otp">OTP resent successfully <i class="fa fa-check" aria-hidden="true" style={{color:"#1de8b4", fontSize:"14px"}}></i></span>
        }else if(!this.state.otppresent){
            resentotpshow = <span className="resend-otp" onClick={this.resendotp}>Resend OTP</span>
        }
      return (
<div>
    <Container>
        <Row>
            <Col> <img src = {signupleftbanner} alt="Banner" className = "leftbanner"/></Col>
            <Col className = "home-right">
                <form className="formContainer-verify">
                <a href="https://www.myleaders.in"> <img src = {Logo} alt = "logo"  width="180px" height = "40px" className = "icon-logo" /></a>
                    <div className= "inspire-text"> 
                        <span >Inspire to Impact</span> 
                    </div>
                    <div>  
                        <span className="otptext">Enter OTP</span>
                    </div>
                    <div  className = "input-field-container-otp">
                        <div id="divOuter">
                            <div id="divInner">
                                <input id="partitioned" type="number" onChange = {this.handleChange} maxLength="4" onKeyPress="if(this.value.length==4) return false;" value={otpvalue}/>
                            </div>
                        </div>
                    </div>
                    <div className="validation-error-messge">
                        {this.state.otpError}</div>
                        <div style={{marginTop:"20px"}}>{resentotpshow}</div>
                        <div style={{fontSize:"12px",color:"#007bff"}}>{resenttext}</div>
                        
                    <input className="formButtons-verify" type="submit" value="SUBMIT"  onClick = {this.Verifyotp} />
                    <div className="buttom-paragraph">
                        <span className="paratext">Agreement, Privacy, Policy and CookiePolicy."  with "By Clicking agree and join, you agree to the Myleader User Agreement, Privacy Policy and Cookie Policy. "
                        </span>
                    </div>
                    <div className="buttom-verify-text">
                        <span className = "user-text-verify">I'm an existing user. </span><Link to='/login'><span className = "user-text-login">Log In</span></Link>
                    </div>
                </form>
            </Col>
        </Row>
    </Container>
    <Footer/>
</div>
)}
}
export default Verify   
