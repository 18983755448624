import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Forgotimg from "../../../containers/Images/forgot.png";
import Logo from "../../../containers/Images/Logo.png";
import { toast } from "react-toastify";
import "../Recover/Recover.css";
import "../../Signup/Verify/Verify.css";
import axios from "axios";
import { Link } from "react-router-dom";
const intialstate = {
  otpError: "",
  passwordError: "",
  cnfrmpasswordError: "",
};
export class Recoverhome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intialstate,
      trackme: "",
      otpresent: false,
      otp: "",
      resentotpshow: true,
      otpsent: false,
      password: "",
      confirmpassword: "",
    };
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  componentDidMount() {
    // if(!this.props.location.state){
    //     this.props.history.push('/*')
    // }
  }
  validate = () => {
    let otpError;
    let passwordError;
    let cnfrmpasswordError;
    if (!this.state.otp) {
      otpError = " Please enter OTP";
      this.setState({ otpError });
    } else if (this.state.otp.length < 4) {
      otpError = "Enter four digits of OTP";
      this.setState({ otpError });
      return false;
    } else if (!this.state.otp.match(/^[0-9]{4}$/)) {
      otpError = "Enter valid OTP";
      this.setState({ otpError });
      return false;
    } else {
      this.setState({ otpError: null });
    }
    if (!this.state.password) {
      passwordError = "Enter password";
      this.setState({ passwordError });
      return false;
    } else if (this.state.password.length < 6) {
      passwordError = "Enter strong password";
      this.setState({
        passwordError,
      });
      return false;
    } else {
      this.setState({ passwordError: null });
    }

    if (!this.state.confirmpassword) {
      cnfrmpasswordError = "Enter confirm password";
      this.setState({ cnfrmpasswordError });
      return false;
    }
    if (this.state.password !== this.state.confirmpassword) {
      cnfrmpasswordError = "Password and Confirm password must be same";
      this.setState({ cnfrmpasswordError });
      return false;
    } else {
      this.setState({ cnfrmpasswordError: null });
    }
    return true;
  };
  handlesumit = (event) => {
    event.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.setState(intialstate);
      axios
        .put(
          process.env.REACT_APP_BASE_URL +
            `Follower/forgotpassword/${this.props.location.state.mobilenumber}`,
          {
            otp: this.state.otp,
            pwd: this.state.password,
            confrm: this.state.confirmpassword,
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            toast.success("Password changed successfully", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              className: "foo-bar",
            });
            setTimeout(() => {
              this.props.history.push({
                pathname: "/login",
              });
            }, 1000);
          } else if (response.data.status === 403) {
            toast.error("Invalid OTP", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              className: "foo-bar-error",
            });
          } else {
            toast.error("Server error", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              className: "foo-bar-error",
            });
          }
        });
    }
  };
  resendotp = () => {
    axios
      .post(process.env.REACT_APP_BASE_URL + `Follower/followersignin`, {
        mobile: this.props.location.state.mobilenumber,
      })
      .then((response) => {
        this.setState({ otpresent: "OTP resent successfully" });
        setTimeout(() => {
          this.setState({ otpresent: false });
        }, 10000);
      });
  };
  render() {
    let resenttext;
    let resentotpshow;
    if (this.state.otpresent) {
      resenttext = (
        <span>
          OTP resent successfully{" "}
          <i
            class="fa fa-check"
            aria-hidden="true"
            style={{ color: "#1de8b4", fontSize: "14px" }}
          ></i>
        </span>
      );
    } else if (!this.state.otppresent) {
      resentotpshow = (
        <span className="resend-otp" onClick={this.resendotp}>
          Resend OTP
        </span>
      );
    }
    return (
      <div>
        {" "}
        <container-fluid>
          <Row>
            <Col>  <a href="https://www.myleaders.in">
              <img className="logo-image" src={Logo} alt="logo" /></a>
            </Col>
            <Col>
              <button
                className="signup-button-recover"
                onClick={() => {
                  this.props.history.push("/signup");
                }}
              >
                Sign-Up
              </button>
            </Col>
          </Row>{" "}
          <div className="menuhr" />
          <Row>
            <Col className="left">
              <img
                className="forgot-left-image"
                src={Forgotimg}
                alt="recover"
              />
            </Col>
            <Col className="right">
              <div className="formContainer-others">
                <p className="forgot-text">Did you forget your password ?</p>
                <div>
                  <span className="otptext-forgot">Enter OTP</span>
                </div>
                <div className="input-field-container-otp">
                  <div id="divOuter">
                    <div id="divInner">
                      <input
                        id="partitioned"
                        type="text"
                        name="otp"
                        onChange={this.handleChange}
                        maxLength="4"
                        onKeyPress="if(this.value.length==4) return false;"
                        defaultValue={this.state.otp}
                      />
                    </div>
                  </div>
                </div>
                <div className="validation-error-messge">
                  {this.state.otpError}
                </div>
                <div>{resentotpshow}</div>
                <div style={{ fontSize: "12px", color: "#007bff" }}>
                  {resenttext}
                </div>
                <input
                  className="input-field-recover"
                  placeholder="New Password"
                  type="password"
                  onChange={this.handleChange}
                  defaultValue={this.state.password}
                  name="password"
                />
                <div className="error-message-recover">
                  {this.state.passwordError}
                </div>
                <input
                  className="input-field-recover"
                  placeholder="Confirm Password"
                  type="password"
                  name="confirmpassword"
                  onChange={this.handleChange}
                  defaultValue={this.state.confirmpassword}
                />
                <div className="error-message-recover">
                  {this.state.cnfrmpasswordError}
                </div>
                <button
                  className="formButtons"
                  type="submit"
                  name="signup_submit"
                  onClick={this.handlesumit}
                >
                  submit
                </button>
                <div ClassName="card-buttom-text">
                  {" "}
                  <span className="par">I am new to myleaders &nbsp;</span>
                  <Link to="/login">
                    {" "}
                    <span
                      className="joinnow-recover"
                      style={{ cursor: "pointer" }}
                    >
                      Log In
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </container-fluid>
      </div>
    );
  }
}
export default Recoverhome;
