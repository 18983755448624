import React, { Component } from 'react'
import Axios from 'axios'
import './Leadersfeednav.css'
import altprofile from '../../../containers/Images/altprofile.png'
import {withRouter} from 'react-router'
class Searchlist extends Component {
constructor(props) {
    super(props)
    this.state = {
        leader_details:[],
        post_leader_id:'',
        profilephoto1:'',
        showsearchlist:false,
        searchname:'',
        searchString:this.props.searchString,
        leader_details1:[]
    }
}
componentDidMount() {
    Axios.get(process.env.REACT_APP_BASE_URL+`myLeaders/leaderprofiledata/${this.props.leader_id}`)
    .then(response=>{
        this.setState({
            leader_details:response.data
        })
    })
    }
    handletarget=(leader_id) =>{
        this.props.history.push({
            pathname:'/targetprofile',
            state:{
                id:leader_id,
                sid:this.props.leader_id
            }
        })
    }
   render() {
       
    var leader_details = this.state.leader_details,
    searchString = this.props.searchString.trim().toLowerCase();
if (searchString.length > 0) {
  leader_details = leader_details.filter(function(i) {
     return i.display_name.toLowerCase().match(searchString);
  });
}
let noleaders;
if(leader_details.length == 0){
    noleaders = <div className="noledaersfound-label">No Leaders Found</div>
}
// console.log('this.props.searchString', this.props.searchString)
// console.log('leader_details', leader_details)
    return (
        <div>
              <div className="search-div"> 
              {noleaders}
               {leader_details.map((i , index) =>(
                   <div onClick={this.handletarget.bind(this,i.leader_id)}>
                        {(()=>{
                            if(i.profile === null){
                                return <div > <img src={altprofile} width="50px" alt="" className="search-bar-image"/>{i.display_name}
                               </div>
                            }else {
                                  return <div><img src={process.env.REACT_APP_BASE_URL+`${i.profile}`} alt="" width="50px" className="search-bar-image"/><span>{i.display_name} </span></div>
                            }
                        })()} </div>
              )) } 
            </div>    
    </div>
    )
  }
}
export default withRouter(Searchlist)
