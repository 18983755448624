import React, { Component } from "react";
import "./Leaderprofilenav.css";
import SmallLogo from "../../../containers/Images/smallLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Searchlist from "../../Leadersfeed/Leaderfeednav/Searchlist";
import altprofile from "../../../containers/Images/altprofile.png";
//import { div, div } from 'react-bootstrap'
import Axios from "axios";
class Leaderfeednav extends Component {
  state = {
    leader_details: [],
    post_leader_id: "",
    profilephoto1: "",
    searchString: "",
    logout: false,
  };
  handleout = () => {
    localStorage.clear();
  };
  componentDidMount() {
    this.userData = JSON.parse(localStorage.getItem("profilephoto"));

    if (localStorage.getItem("profilephoto")) {
      this.setState({
        profilephoto1: this.userData.profilephoto,
      });
    } else {
      this.setState({
        profilephoto1: "",
      });
    }
  }
  handleshow = () => {
    this.setState({ logout: this.state.logout === false ? true : false });
  };
  handlesearch = (event) => {
    this.setState({ searchString: event.target.value });
  };

  render() {
    const profilephoto = this.props.profilephoto;
    const url = process.env.REACT_APP_BASE_URL + `${profilephoto}`;
    let logout;
    if (this.state.logout) {
      logout = (
        <div className="log-out-card">
          <span className="log-out-title">
            {" "}
            <a
              href="https://myleaders.in/terms-and-conditions/"
              target="_blank"
            >
              {" "}
              Terms and Conditions
            </a>
          </span>
          <hr className="hr-logout" />
          <span className="log-out-title">
            {" "}
            <a href="https://myleaders.in/privacy-policy/" target="_blank">
              Privacy Policy
            </a>
          </span>
          <hr className="hr-logout" />
          <Link to="/login">
            <span className="log-out-title" onClick={this.handleout}>
              Log Out
            </span>
          </Link>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="nav-parent">
          <Link to="/feed">
            {" "}
            <img className="nav-image" src={SmallLogo} alt="Logoimage"></img>
          </Link>

          <input
            className="search-bar-input-field"
            type="text"
            placeholder="Search Leaders..."
            onChange={this.handlesearch}
          />
          <span className="search-icon-feed">
            <FontAwesomeIcon icon={faSearch} />
          </span>
          <span>
            <span className="main-nav js--main-nav">
              <span style={{ textAlign: "center", marginLeft: "50px" }}>
                <Link to="/feed">
                  {" "}
                  <span
                    className="leadersmenutext"
                    style={{ marginRight: "20px" }}
                  >
                    Home
                  </span>
                </Link>

                <Link to="/profile">
                  {(() => {
                    if (this.state.profilephoto1) {
                      return (
                        <img
                          className="profile-nav"
                          alt=""
                          src={
                            process.env.REACT_APP_BASE_URL +
                            `${this.state.profilephoto1}`
                          }
                        />
                      );
                    } else if (this.props.photo) {
                      return (
                        <img
                          className="profile-nav"
                          alt=""
                          src={
                            process.env.REACT_APP_BASE_URL +
                            `${this.props.photo}`
                          }
                        />
                      );
                    } else if (
                      (!this.state.profilephoto1 &&
                        this.props.photo === null) ||
                      this.props.photo === undefined ||
                      this.props.photo === "undefined"
                    ) {
                      return (
                        <img alt="" className="profile-nav" src={altprofile} />
                      );
                    }
                  })()}
                </Link>
              </span>
              <span className="rightdrpdwn">
                <i
                  class="fa fa-sort-desc"
                  aria-hidden="true"
                  onClick={this.handleshow}
                  style={{ color: "#4d4d4d" }}
                ></i>
                {logout}
              </span>
            </span>
          </span>
          {this.state.searchString && this.props.follower_id && (
            <Searchlist
              searchString={this.state.searchString}
              leader_id={this.props.follower_id}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(Leaderfeednav);
