import React, { Component } from 'react'
import Axios from 'axios'
import '../Majoraccomplishment/Majoraccomplishment.css'

class Socialservice extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       details:[]
    }
  }
  componentDidMount() {
    Axios.get(process.env.REACT_APP_BASE_URL+`target/targetservice/${this.props.follower_id}/${this.props.leader_id}`)
    .then(response=>{
      if(response.data.status != 201){
      this.setState({
        details:response.data.Results
      })}
    })
  }
  render() {
    return (
      <div>
      <div className="major-accoumplishment-card">
        <span className="major-accomplishment-heading" >Social Service</span>
   {this.state.details.map((details , index )=>(
        <div className="split-cards-profile-containers">
          {(()=>{
                          if(details.title !== "undefined" && details.title !== "" && details.title !== null){
                                              return <div><label className="label-accomplishment">Title</label><span className="accomplishment-title-card">: {details.title}</span></div>
                          }
          })()}
   
          {(()=>{
                          if(details.description !== "undefined" && details.description !== "" && details.description !== null){
                                              return <div><label className="label-accomplishment">Description</label><span className="accomplishment-title-card"> : {details.description}</span></div>
                          }
          })()}
            
            {(()=>{
                          if(details.upload !== "undefined" && details.upload !== "" && details.upload !== null){
                                              return<div><label className="label-accomplishment">Link:</label>
                                              <a href={`${details.upload}`} target="_blank">{details.upload}</a></div>
                                                
                          }
          })()}
           
           {(()=>{
                          if(details.document !== "undefined" && details.document !== "" && details.document !== null){
                                              return<div>
                                                <a href={process.env.REACT_APP_BASE_URL+`${details.document}`} target="_blanlk" download={process.env.REACT_APP_BASE_URL+`${details.document}`}>
                                                <button className="view-document">View Document</button></a> </div>              
                          }
          })()}
           <hr className="hr-tag-profile"/>
            </div>
      ))}
      </div>
      </div>
    )
  }
}

export default Socialservice