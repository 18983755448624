import React from 'react'
import './Footer.css'
const Footer = (props)=>{
  return(
    <div class="footer">
    <label className="footer-all-app"><a href="https://www.obaps.in/" target="_blank" ><span className="footer-labe-obaps">Powered by OBAPS</span></a>
    </label>
  </div>
  )
}
export default Footer