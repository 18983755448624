import React, { Component } from 'react'
import Axios from 'axios'
import '../Majoraccomplishment/Majoraccomplishment.css'
import Moment from 'react-moment'

class Honorandaward extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       details:[]
    }
  }
  componentDidMount() {
    Axios.get(process.env.REACT_APP_BASE_URL+`target/targetawards/${this.props.follower_id}/${this.props.leader_id}`)
    .then(response=>{
      if(response.data.status != 201){
      this.setState({
        details:response.data.Results
      })}
    })
  }

  render() {
    return (
      <div>
      <div className="major-accoumplishment-card">
        <span className="major-accomplishment-heading" >Honor and Award</span>
      {this.state.details.map((details , index )=>(
        <div className="split-cards-profile-containers">
          {(()=>{
                          if(details.title !== "undefined" && details.title !== "" && details.title !== null){
                                              return <div><label className="label-accomplishment">Title</label><span className="accomplishment-title-card">: {details.title}</span></div>
                          }
          })()}
            {(()=>{
                          if(details.awarded_by_name !== "undefined" && details.awarded_by_name !== "" && details.awarded_by_name !== null){
                                              return <div><label className="label-accomplishment">Awarded By</label><span className=" accomplishment-title-card"> : {details.awarded_by_name}</span></div>
                          }
          })()}
  
          {(()=>{
                          if(details.description !== "undefined" && details.description !== "" && details.description !== null){
                                              return <div><label className="label-accomplishment">Description</label><span className="accomplishment-title-card"> : {details.description}</span></div>
                          }
          })()}
            
            {(()=>{
                          if(details.upload !== "undefined" && details.upload !== "" && details.upload !== null){
                                              return<div><label className="label-accomplishment">Link:</label>
                                              <a href={`${details.upload}`} target="_blank">{details.upload}</a></div>
                                                
                          }
          })()}
           
           {(()=>{
                          if(details.document !== "undefined" && details.document !== "" && details.document !== null){
                                              return<div>
                                                <a href={process.env.REACT_APP_BASE_URL+`${details.document}`} target="_blanlk" download={process.env.REACT_APP_BASE_URL+`${details.document}`}>
                                                <button className="view-document">View Document</button></a></div>
                          }
          })()}
            {(()=>{
                          if(details.awarded_on !== "undefined" && details.awarded_on !== "" && details.awarded_on !== null){
                                              return <div><label className="label-accomplishment">Awarded On</label><span className="accomplishment-title-card">: <Moment format="DD/MM/YYYY">
                                              {details.awarded_on}
                                          </Moment></span></div>
                          }
          })()}
           <hr className="hr-tag-profile"/>
            </div>
      ))}
      </div>
      </div>
    )
  }
}
export default Honorandaward

