import React, { Component } from "react";
import "./Education.css";
import { Row, Col } from "react-bootstrap";
import Moment from "react-moment";
class Moreeducation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      education: this.props.education,
    };
  }
  render() {
    let date;
    return (
      <div className="more-education-container">
        <div className="education-heading">Education Details</div>
        <hr />{" "}
        {this.state.education.map((details) => {
          return (
            <div className="split-container" key={details.institution}>
              <Row>
                <Col md={2}>
                  <label className="label-education">Institution</label>
                </Col>
                <Col md={10}>: {details.institution}</Col>
              </Row>
              <Row>
                <Col md={2}>
                  <label className="label-education">Degree</label>
                </Col>
                <Col md={10}>: {details.degree}</Col>
              </Row>
              {(() => {
                if (details.description) {
                  return (
                    <Row>
                      <Col md={2}>
                        <label className="label-education">Description</label>
                      </Col>
                      <Col md={10}>: {details.description}</Col>
                    </Row>
                  );
                }
              })()}
              {(() => {
                if (details.end_date) {
                  return (
                    <Row>
                      <Col md={2} sm={2}>
                        <label className="label-education">Start Date</label>
                      </Col>
                      <Col md={4} sm={4}>
                        :{" "}
                        <Moment format="MMM-YYYY">{details.start_date}</Moment>
                      </Col>

                      <Col md={2}>
                        <label
                          className="label-education"
                          style={{ float: "left" }}
                        >
                          End Date
                        </label>
                      </Col>
                      <Col md={4}>
                        :<Moment format="MMM-YYYY">{details.end_date}</Moment>
                      </Col>
                    </Row>
                  );
                } else if (!details.end_date) {
                  return (
                    <Row>
                      <Col md={2}>
                        <label className="label-education">Start Date</label>
                      </Col>
                      <Col md={4} sm={4}>
                        :{" "}
                        <Moment format="MMM-YYYY">{details.start_date}</Moment>
                      </Col>

                      <Col md={2}>
                        <label className="label-education">- Present</label>
                      </Col>
                    </Row>
                  );
                }
              })()}{" "}
            </div>
          );
        })}{" "}
      </div>
    );
  }
}
export default Moreeducation;
