import React, { Component, Suspense, lazy } from "react";
import axios from "axios";
import Reactthreedots from "../../components/Loader/Reactthreedots";
import { Row, Col } from "react-bootstrap";
import { Switch, Route } from "react-router-dom";
import "./Targetprofile.css";
import Leaderprofilenav from "../../components/Leaderprofile/Leaderprofilenav/Leaderprofilenav";
import Activity from "../../components/Targetprofile/Activity/Activity";
import Profileheader from "../../components/Targetprofile/Profileheader/Profileheader";
import About from "../../components/Targetprofile/About/About";
import Education from "../../components/Targetprofile/Education/Education";
import Sociallinks from "../../components/Targetprofile/Sociallinks/Sociallinks";
import Mydairies from "../../components/Targetprofile/Mydairies/Mydairies";
import Fiveyearplantarget from "../../components/Targetprofile/Fiveyearplantarget/Fiveyearplantarget";
import Experiance from "../../components/Targetprofile/Experiance/Experiance";
import Majoraccomplishment from "../../components/Targetprofile/Majoraccomplishment/Majoraccomplishment";
import Socialservice from "../../components/Targetprofile/Socialservice/Socialservice";
import Honorandaward from "../../components/Targetprofile/Honorandaward/Honorandaward";
import Leaderdetails from "../../components/Targetprofile/Leaderdetails/Leaderdetails";
import Profilecontent from "../../components/Targetprofile/Profilecontent/Profilecontent";
const Profilefeed = React.lazy(() =>
  import("../../components/Targetprofile/Profilefeed/Profilefeed")
);
const Problemfeed = React.lazy(() =>
  import("../../components/Targetprofile/Problemfeed/Problemfeed")
);
const Proposalfeed = React.lazy(() =>
  import("../../components/Targetprofile/Proposalfeed/Proposalfeed")
);
const Problemtaged = React.lazy(() =>
  import("../../components/Targetprofile/Problemtaged/Problemtaged")
);
class Targetprofile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile_details: "",
      profilephoto: "",
      leader_id: this.props.location.state.id,
      user_vision: "",
      follower_id: this.props.location.state.sid,
      profile_details1: "",
    };
  }
  componentDidMount() {
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
          `target/targetprofile/${this.state.follower_id}/F/${this.state.leader_id}/L`
      )
      .then((response) => {
        this.setState({
          profile_details: response.data.Results.profiledata[0],
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });

    axios
      .get(
        process.env.REACT_APP_BASE_URL +
          `target/targetdetails/${this.state.follower_id}/${this.state.leader_id}`
      )
      .then((response) => {
        this.setState({
          profile_details1: response.data.Results[0],
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  handlereload = () => {
    window.location.reload();
  };
  render() {
    if (this.props.location.state.id != this.state.leader_id) {
      this.handlereload();
    }
    return (
      <div>
        <Leaderprofilenav follower_id={this.state.follower_id} />
        <Row className="leader-feed-alignment-div">
          {this.state.leader_id && (
            <Profileheader
              profilephoto={this.state.profile_details.profile}
              profile_details={this.state.profile_details}
              leader_id={this.state.leader_id}
              follower_id={this.props.location.state.sid}
              cover_photo={this.state.profile_details.coverpage}
            />
          )}
          <Col className="left-profile">
            {this.state.leader_id && this.state.follower_id && (
              <Activity
                leader_id={this.state.leader_id}
                s_id={this.state.follower_id}
              />
            )}

            <div className="laptop_view">
              {this.state.leader_id && (
                <Education leader_id={this.state.leader_id} />
              )}
              {this.props.location.state.id && (
                <Mydairies leader_id={this.props.location.state.id} />
              )}
              {this.state.leader_id && (
                <Leaderdetails leader_id={this.state.leader_id} />
              )}
              <About
                profile_details={this.state.profile_details1}
                follower_id={this.state.follower_id}
                leader_id={this.state.leader_id}
              />
              {this.state.leader_id && (
                <Sociallinks
                  leader_id={this.state.leader_id}
                  follower_id={this.state.follower_id}
                />
              )}
            </div>
          </Col>
          <Col className="right-profile">
            {/*-------------------------- Vision-------------------------------- */}
            <Switch>
              <Route
                exact
                path="/targetprofile/myactivity/posts"
                render={(props) => (
                  <Suspense fallback={<Reactthreedots />}>
                    {this.state.leader_id && (
                      <Profilefeed
                        leader_id={this.state.leader_id}
                        s_id={this.state.s_id}
                        {...props}
                      />
                    )}
                  </Suspense>
                )}
              />

              <Route
                path="/targetprofile/myactivity/problems"
                render={(props) => (
                  <Suspense fallback={<Reactthreedots />}>
                    {this.state.leader_id && (
                      <Problemfeed
                        leader_id={this.state.leader_id}
                        follower_id={this.state.follower_id}
                        {...props}
                      />
                    )}
                  </Suspense>
                )}
              />

              <Route
                path="/targetprofile/myactivity/proposals"
                render={(props) => (
                  <Suspense fallback={<Reactthreedots />}>
                    {this.state.leader_id && (
                      <Proposalfeed
                        leader_id={this.state.leader_id}
                        follower_id={this.state.follower_id}
                        {...props}
                      />
                    )}
                  </Suspense>
                )}
              />

              <Route
                path="/targetprofile/myactivity/problemtaged"
                render={(props) => (
                  <Suspense fallback={<Reactthreedots />}>
                    {this.state.leader_id && (
                      <Problemtaged
                        leader_id={this.state.leader_id}
                        follower_id={this.state.follower_id}
                        {...props}
                      />
                    )}
                  </Suspense>
                )}
              />
              <Route
                path="/targetprofile"
                render={(props) => (
                  <Suspense fallback={<Reactthreedots />}>
                    {this.state.leader_id && (
                      <Profilecontent
                        leader_id={this.state.leader_id}
                        follower_id={this.state.follower_id}
                        {...props}
                      />
                    )}
                  </Suspense>
                )}
              />
            </Switch>
          </Col>
        </Row>
        <div className="mobile_view_section">
        <Row >
          <Col>
            {this.state.leader_id && (
              <Education leader_id={this.state.leader_id} />
            )}
            {this.props.location.state.id && (
              <Mydairies leader_id={this.props.location.state.id} />
            )}
            {this.state.leader_id && (
              <Leaderdetails leader_id={this.state.leader_id} />
            )}
            <About
              profile_details={this.state.profile_details1}
              follower_id={this.state.follower_id}
              leader_id={this.state.leader_id}
            />
            {this.state.leader_id && (
              <Sociallinks
                leader_id={this.state.leader_id}
                follower_id={this.state.follower_id}
              />
            )}
          </Col>
        </Row>
        </div>
        {/* --------------ABOUT-------------- */}
      </div>
    );
  }
}

export default Targetprofile;
