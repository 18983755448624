import React, { Component } from "react";
import "../../../containers/Leaderprofile/Leaderprofile.css";
import Axios from "axios";
import Fiveyearplantarget from "../Fiveyearplantarget/Fiveyearplantarget";
import Experiance from "../../Targetprofile/Experiance/Experiance";
import Majoraccomplishment from "../Majoraccomplishment/Majoraccomplishment";
import Socialservice from "../Socialservice/Socialservice";
import Honorandaward from "../Honorandaward/Honorandaward";
class Profilecontent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_vision: "",
    };
  }
  componentDidMount() {
    this.setState({ user_vision: "" });
    Axios.get(
      process.env.REACT_APP_BASE_URL +
        `target/targetvision/${this.props.follower_id}/${this.props.leader_id}`
    )
      .then((response) => {
        this.setState({
          user_vision: response.data.Results[0].vision,
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {});
  }
  render() {
    return (
      <div>
        {/* -------------------------VISION-------------------------------- */}
        <div className="vision-container">
          <div className="vision">Vision</div>
          <span className="vision-text">{this.state.user_vision}</span>
        </div>
        {/* --------------------------------------5 Year plan---------------------- */}
        {this.props.leader_id && (
          <Fiveyearplantarget
            leader_id={this.props.leader_id}
            follower_id={this.props.leader_id}
          />
        )}
        {this.props.leader_id && (
          <Experiance
            leader_id={this.props.leader_id}
            follower_id={this.props.follower_id}
          />
        )}
        {this.props.leader_id && (
          <Majoraccomplishment
            leader_id={this.props.leader_id}
            follower_id={this.props.follower_id}
          />
        )}
        {this.props.leader_id && (
          <Socialservice
            leader_id={this.props.leader_id}
            follower_id={this.props.follower_id}
          />
        )}
        {this.props.leader_id && (
          <Honorandaward
            leader_id={this.props.leader_id}
            follower_id={this.props.follower_id}
          />
        )}
      </div>
    );
  }
}
export default Profilecontent;
