import React, { Component } from 'react'
import  './Sociallinks.css'
import Axios from 'axios';
class Sociallinks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      social_links:'',
      leader_id:"",
      s_nodata:'',
      show:true
    }
  }
  componentDidMount(){
    Axios.get(process.env.REACT_APP_BASE_URL+`target/targetlinks/${this.props.follower_id}/${this.props.leader_id}`)
    .then(response=>{
      if(response.data.status == 200){
        this.setState({
            social_links:response.data.Results[0],
            show:true
        })
      }else{
        this.setState({show:false})
      }
    })
  }
  render() {
    return (
      <div className="social-link-container" style={{marginBottom:"30px"}}>
      <span className="edu_text">Social Links</span> 
      <div>
   <div className="Social-links">
     <center>

     {(()=>{
      if(this.state.show){
        if(this.state.social_links.websiteId !== "undefined" && this.state.social_links.websiteId !==""){
          return(
            <a href={`${this.state.social_links.websiteId}`} target="_blank">
            <i class="fa fa-globe" aria-hidden="true" style={{ marginRight:"35px" ,marginLeft:"-20px",cursor:"pointer"}}></i></a>
          )
        }
      }
     })()}

   {(()=>{
      if(this.state.show){
        if(this.state.social_links.facebookId !=="undefined" && this.state.social_links.facebookId!==""){
          return(
            <a href={`${this.state.social_links.facebookId}`} target="_blank"><i class="fa fa-facebook-official" aria-hidden="true" style={{marginRight:"35px",cursor:"pointer"}}></i></a>
          )
        }}
   })()}
   
   {(()=>{
      if(this.state.show){
          if(this.state.social_links.instagramId !=="undefined" && this.state.social_links.instagramId !== ""){
            return(
              <a href={"http://"+this.state.social_links.instagramId} target="_blank">
              <i class="fa fa-instagram" aria-hidden="true"style={{marginRight:"35px",cursor:"pointer"}}></i></a>
            )
            }
          }
   })()}
   
   {(()=>{
      if(this.state.show){
      if(this.state.social_links.twitterId !== "undefined" && this.state.social_links.twitterId !== ""){
        return(
          <a href={`${this.state.social_links.twitterId}`} target="_blank">
          <i class="fa fa-twitter-square" aria-hidden="true" style={{marginRight:"35px",
           cursor:"pointer"}}></i></a>
        )
      }}
   })()}
   
  {(()=>{
     if(this.state.show){
        if(this.state.social_links.linkedIn !== "undefined" && this.state.social_links.linkedIn !== ""){
          return(
            <a href={`${this.state.social_links.linkedIn}`} target="_blank">
            <i class="fa fa-linkedin-square" aria-hidden="true" style={{cursor:"pointer"}}></i></a>
          )
        }}
  })()}

</center></div></div></div>
    )
  }
}
export default Sociallinks
