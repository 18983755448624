import React, { Component } from 'react'
import Axios from 'axios'
import './Fiveyearplan.css'

class Fiveyearplan extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      plan_details:[],
       plan1:'',
       plan2:'',
       plan3:'',
       plan4:'',
       plan5:'',
    }
  }
  
  componentDidMount() {
    this.setState({plan_details:[]})
    Axios.get(process.env.REACT_APP_BASE_URL+`target/targetplans/${this.props.follower_id}/${this.props.leader_id}`)
    .then(response=>{ 
if(response.data.status !==201){
        this.setState({
            plan_details:response.data.Results[0],
            plan1:response.data.Results[0].plan1,
            plan2:response.data.Results[0].plan2,
            plan3:response.data.Results[0].plan3,
            plan4:response.data.Results[0].plan4,
            plan5:response.data.Results[0].plan5
        })}
    })
  }
  render() {
    let plan1;
    let plan2;
    let plan3;
    let plan4;
    let plan5;
    if(this.state.plan_details.status !== 201){
    if(this.state.plan1 !== "undefined" && this.state.plan1 !== ""){
      plan1=<li className="profile-content-plans">{this.state.plan1}</li>
    }
    if(this.state.plan2 !== "undefined" && this.state.plan2 !== ""){
      plan2=<li className="profile-content-plans">{this.state.plan2}</li>
    }
    if(this.state.plan3 !== "undefined" && this.state.plan3 !== ""){
      plan3=<li className="profile-content-plans">{this.state.plan3}</li>
    }
    if(this.state.plan4 !== "undefined" && this.state.plan4 !== ""){
      plan4=<li className="profile-content-plans">{this.state.plan4}</li>
    }
    if(this.state.plan5 !== "undefined" && this.state.plan5 !== ""){
      plan5=<li className="profile-content-plans">{this.state.plan5}</li>
    }
  }
    return (
      <div>
           <div className="vision-container2">
                <div>  <span className="vision">My personal 5 year plan for a better India</span>
                </div>       
                
                      
                        {plan1}
                        {plan2}
                        {plan3}
                        {plan4}
                        {plan5}
                        
                </div>  
        
      </div>
    )
  }
}

export default Fiveyearplan
