import React, { Component } from 'react'
import '../../../containers/Leaderprofile/Leaderprofile.css'
import Axios from 'axios'
class Leaderdetails extends Component {
    state={
        details:''
    }

    componentDidMount(){
        Axios.get(process.env.REACT_APP_BASE_URL+`target/targetprofiledetails/${this.props.follower_id}/${this.props.leader_id}`)
        .then(response =>{
            this.setState({
                details:response.data
            })
        })
    }
    render() {
        let ward;
        if(this.state.details.ward_no !== undefined){
            ward = <tr>
            <td className="heading">Ward Number</td>
            <td className="display-content">: {this.state.details.ward_no}</td>
            
        </tr> 
        }
        return (
            <div>
                  <div className="profile-content-info-del1">

<div className="profile-content-info-header">
    
    <span className="card-heading">Leader Details</span> 

</div>
<div className="profile-content-info-body">
    <table>
        <tbody>
            <tr>
                <td className="heading">Country</td>
                <td className="display-content">: {this.state.details.country_name}</td>
                <td className="display-content"></td>
            </tr>
            <tr>
                <td className="heading">State </td>
                <td className="display-content">: {this.state.details.state_name}</td>
                <td className="display-content"> </td>
            </tr>
            <tr>
                <td className="heading">District</td>
                <td className="display-content">: {this.state.details.district_name}</td>
                <td className="display-content"></td>
            </tr>
             <tr>
                <td className="heading">Parliament Constituency</td>
                <td className="display-content">: {this.state.details.pc_name}</td>
                
            </tr> 
            <tr>
                <td className="heading">Assembly Constituency</td>
                <td className="display-content">: {this.state.details.ac_name}</td>
                
            </tr> 
            <tr>
                <td className="heading">Voter ID</td>
                <td className="display-content">: {this.state.details.voter_id}</td>
                
            </tr> 
            {ward}
        </tbody>
    </table>
    
</div>
</div>
            </div>
        )
    }
}

export default Leaderdetails
