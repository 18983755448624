import React, { Component } from "react";
import Logo from "../../../containers/Images/Logo.png";
import "./Userdetails.css";
import { toast } from "react-toastify";
import axios from "axios";
import Axios from "axios";
import Footer from "../../../components/Footer/Footer";
const intialstate = {
  stateError: "",
  districtError: "",
  parlimentError: "",
  constituencyError: "",
  wardError: "",
  voteridError: "",
};
class Userdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intialstate,
      parliamentaryC: "",
      assemblyC: "",
      assemblies: [],
      assemblies1: [],
      parliments: [],
      parliments1: [],
      states: [],
      states1: [],
      district: [],
      district1: [],
      wards: [],
      wards1: [],
      userdistrict: "",
      userCountry: "India",
      userState: "",
      wardnumber: "",
      voterid: "",
      users: "",
      p_id: [],
      p_id1: false,
      p_id_destry: [],
    };
    this.submitdetails = this.submitdetails.bind(this);
  }
  componentDidMount() {
    // if(!this.props.location.state){
    //     this.props.history.push('/*')
    // }
    Axios.get(process.env.REACT_APP_BASE_URL + `generic/locationdetails`).then(
      (response) => {
        this.setState({
          p_id: response.data.Results,
        });
      }
    );

    Axios.get(process.env.REACT_APP_BASE_URL + `generic/states`).then(
      (response) => {
        this.setState({
          states: response.data,
        });
      }
    );

    Axios.get(process.env.REACT_APP_BASE_URL + `generic/districts`).then(
      (response) => {
        this.setState({
          district: response.data,
        });
      }
    );
    Axios.get(
      process.env.REACT_APP_BASE_URL + `generic/assemblyconstituency`
    ).then((response) => {
      this.setState({
        assemblies: response.data,
      });
    });
    Axios.get(
      process.env.REACT_APP_BASE_URL + `generic/parlimentaryconstituency`
    ).then((response) => {
      this.setState({
        parliments: response.data,
      });
    });
    Axios.get(process.env.REACT_APP_BASE_URL + `generic/warddetails`).then(
      (response) => {
        this.setState({
          wards: response.data,
        });
      }
    );
  }
  setStateHandler = (event) => {
    this.setState({ userState: event.target.value });
    const district = this.state.district.filter(
      (element) => element.state_id == event.target.value
    );
    this.setState({ district1: district });

    const Parliments = this.state.parliments.filter(
      (element) => element.state_id == event.target.value
    );
    this.setState({
      parliments1: Parliments,
    });
  };
  districthandler = (event) => {
    this.setState({
      userdistrict: event.target.value,
    });
  };
  mpHandler = (event) => {
    this.setState({ p_id_destry: this.state.p_id });
    this.setState({ parliamentaryC: event.target.value });
    const assemblies = this.state.assemblies.filter(
      (element) => element.pid == event.target.value
    );
    this.setState({ assemblies1: assemblies });
    setTimeout(() => {
      const p_id = this.state.p_id_destry.filter(
        (element) => element.p_id == this.state.parliamentaryC
      );
      if (p_id.length != 0) {
        this.setState({ p_id1: p_id[0].p_id });
      } else {
        this.setState({ p_id1: false });
      }
    }, 100);
  };

  assemblyHandler = (event) => {
    this.setState({ assemblyC: event.target.value });
    const wards = this.state.wards.filter(
      (element) => element.aid == event.target.value
    );
    this.setState({ wards1: wards });
  };
  handleWardnumber = (event) => {
    this.setState({ wardnumber: event.target.value });
  };
  handlevoterid = (event) => {
    this.setState({ voterid: event.target.value });
  };
  validate = () => {
    let stateError;
    let districtError;
    let parlimentError;
    let constituencyError;
    
    if (!this.state.userState) {
      stateError = "Select a State";
      this.setState({ stateError });
      return false;
    }
    if (!this.state.userdistrict) {
      districtError = "Select a District";
      this.setState({ districtError });
      return false;
    }
    if (!this.state.parliamentaryC) {
      parlimentError = "Select a Parlimentary Constituency";
      this.setState({ parlimentError });
      return false; 
    }
    if (!this.state.assemblyC) {
      constituencyError = " Select an Assembly Constituency";
      this.setState({ constituencyError });
      return false;
    }
   
    
    return true;
  };
  submitdetails = (event) => {
    event.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.setState(intialstate);
      axios
        .post(process.env.REACT_APP_BASE_URL + "Follower/registration-step2", {
          mobile: this.props.location.state.mobilenumber,
          country: 1,
          state: this.state.userState,
          district: this.state.userdistrict,
          p_constituency: this.state.parliamentaryC,
          a_constituency: this.state.assemblyC,
          voterId: this.state.voterid,
          ward_no: this.state.wardnumber,
        })
        .then((response) => {
          if (response.data.status == 200) {
            this.setState({
              follower_id: response.data.follower_id,
            });
            toast.success("Details saved", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              className: "foo-bar",
            });
            setTimeout(() => {
              this.props.history.push({
                pathname: "/profiledetails",
                state: {
                  follower_id: this.state.follower_id,
                  mobilenumber: this.props.location.state.mobilenumber,
                },
              });
            }, 1000);
          } else {
            toast.error("Please check data you have entered", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              className: "foo-bar-error",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  render() {
    let wardshow;
    if (this.state.p_id1) {
      wardshow = (
        <div>
          <label className="label-field">
            Ward Number<span class="required">*</span>
          </label>
          <select
            className="rightbtnfld select-button-field"
            type="text"
            placeholder="Choose"
            onChange={this.handleWardnumber}
          >
            <option value="" disabled selected>
             
            </option>
            {this.state.wards1.map((list) => (
              <option value={list.wid}>{list.ward_no}</option>
            ))}
          </select>
        </div>
      );
    }
    return (
      <div>
        <img src={Logo} alt="logo" className="logo" />
        <div className="bottom-line"></div>
        <div className="header">Welcome</div>
        <div className="header-text">
          Help us connect with people in your constituency and engage with them
          with posts, proposals and topics you care about.
        </div>
        <form className="formContainer-user">
          <div className="select-field">
            <label className="label-field">Country</label>
            <select
              onChange={this.countryHandler}
              className="rightbtnfld select-button-field-disabled"
              required
              disabled
            >
              <option>India</option>
            </select>{" "}
          </div>
          <div className="select-field">
            <label className="label-field">State<span class="required">*</span></label>
            <select
              className="rightbtnfld select-button-field-state"
              onChange={this.setStateHandler}
              defaultValue={this.state.userState}
              required
            >
              <option value="" disabled selected>
                Select State
              </option>
              {this.state.states.map((element) => {
                return (
                  <option value={element.state_id}>{element.state_name}</option>
                );
              })}
            </select>
            <div className="validation-error-messge-userdetails">
              {this.state.stateError}
            </div>
          </div>

          <div className="select-field">
            <label className="label-field">
              District<span class="required">*</span>
            </label>
            <select
              className="rightbtnfld select-button-field"
              onChange={this.districthandler}
              required
            >
              <option value="" disabled selected>
                Select District
              </option>
              {this.state.district1.map((dsrt) => {
                return (
                  <option value={dsrt.district_id}>{dsrt.district_name}</option>
                );
              })}
            </select>
            <div className="validation-error-messge-userdetails">
              {this.state.districtError}
            </div>
          </div>
          <div className="select-field">
            <label className="label-field">
              Parliamentary Constituency<span class="required">*</span>
            </label>
            <select
              className="rightbtnfld select-button-field-parliment"
              onChange={this.mpHandler}
              required
            >
              <option value="" disabled selected>
                Select Parliamentary
              </option>
              {this.state.parliments1.map((parlimnt) => {
                return (
                  <option value={parlimnt.pid}>
                    {parlimnt.constituency_name}
                  </option>
                );
              })}
            </select>
            <div className="validation-error-messge-userdetails">
              {this.state.parlimentError}
            </div>
          </div>
          <div className="select-field">
            <label className="label-field">
              Assembly Constituency<span class="required">*</span>
            </label>
            <select
              className="rightbtnfld select-button-field"
              onChange={this.assemblyHandler}
              required
            >
              <option value="" disabled selected>
                Select Constituency
              </option>
              {this.state.assemblies1.map((asmbl) => {
                return (
                  <option value={asmbl.aid}>{asmbl.constituency_name}</option>
                );
              })}
            </select>
            <div className="validation-error-messge-userdetails">
              {this.state.constituencyError}
            </div>{" "}
          </div>
          {wardshow}
          <div className="validation-error-messge-userdetails">
            {this.state.wardError}
          </div>
          <div>
            <label className="label-field">
              Voter ID
            </label>
            <input
              className="rightbtnfld select-button-field"
              type="text"
              placeholder="UVWX123456"
              maxLength="10"
              style={{ textTransform: "uppercase" }}
              onChange={this.handlevoterid}
            />
          </div>
          <div className="validation-error-messge-userdetails">
            {this.state.voteridError}
          </div>
        </form>
        <div className="button-user">
          <input
            className="formButtons-userdetails"
            type="submit"
            value="Submit"
            onClick={this.submitdetails.bind(this)}
            name="signup_submit"
          />
        </div>
        <Footer />
      </div>
    );
  }
}
export default Userdetails;
