import React, { Component } from "react";
import "../../../containers/Leaderprofile/Leaderprofile.css";
import { Row, Col } from "react-bootstrap";
import "../../../components/Leaderprofile/About/About.css";
import Axios from "axios";
import Moment from "react-moment";
class About extends Component {
  state = {
    profile_details: "",
  };
  componentDidMount() {
    Axios.get(
      process.env.REACT_APP_BASE_URL +
        `target/targetdetails/${this.props.follower_id}/${this.props.leader_id}`
    ).then((response) => {
      this.setState({ profile_details: response.data.Results[0] });
    });
  }
  render() {
    console.log("this.props", this.props);
    let emailId;
    let address;
    let age;
    let gender;
    let assets;
    let ofc_no;
    let ofc_email;
    let ofc_address;
    let dob;
    let liabilities;
    let profession;
    if (
      this.state.profile_details.address !== "null" &&
      this.state.profile_details.address !== null &&
      this.state.profile_details.address !== ""
    ) {
      address = (
        <Row className="about-row">
          <Col className="heading" style={{ paddingLeft: "6%" }}>
            Address{" "}
          </Col>
          <Col className="display-content-about">
            :{this.state.profile_details.address}
          </Col>
        </Row>
      );
    }
    if (
      this.state.profile_details.age !== "null" &&
      this.state.profile_details.age != null &&
      this.state.profile_details.age !== ""
    ) {
      age = (
        <Row className="about-row">
          <Col className="heading" style={{ paddingLeft: "6%" }}>
            Age{" "}
          </Col>
          <Col className="display-content-about">
            :{this.state.profile_details.age}
          </Col>
        </Row>
      );
    }
    if (
      this.state.profile_details.liabilities !== "null" &&
      this.state.profile_details.liabilities != null &&
      this.state.profile_details.liabilities !== ""
    ) {
      liabilities = (
        <Row className="about-row">
          <Col className="heading" style={{ paddingLeft: "6%" }}>
            Liabilities{" "}
          </Col>
          <Col className="display-content-about">
            :{this.state.profile_details.liabilities}
          </Col>
        </Row>
      );
    }
    if (
      this.state.profile_details.profession !== "null" &&
      this.state.profile_details.profession != null &&
      this.state.profile_details.profession !== ""
    ) {
      profession = (
        <Row className="about-row">
          <Col className="heading" style={{ paddingLeft: "6%" }}>
            Profession{" "}
          </Col>
          <Col className="display-content-about">
            :{this.state.profile_details.profession}
          </Col>
        </Row>
      );
    }
    if (
      this.state.profile_details.emailId !== "null" &&
      this.state.profile_details.emailId != null &&
      this.state.profile_details.emailId !== ""
    ) {
      emailId = (
        <Row className="about-row">
          <Col className="heading" style={{ paddingLeft: "6%" }}>
            Email ID
          </Col>
          <Col className="display-content-about">
            :{this.state.profile_details.emailId}
          </Col>
        </Row>
      );
    }
    if (
      this.state.profile_details.gender !== "null" &&
      this.state.profile_details.gender != null &&
      this.state.profile_details.gender !== ""
    ) {
      gender = (
        <Row className="about-row">
          <Col className="heading" style={{ paddingLeft: "6%" }}>
            Gender
          </Col>
          <Col className="display-content-about">
            :{this.state.profile_details.gender}
          </Col>
        </Row>
      );
    }
    if (
      this.state.profile_details.assets !== "null" &&
      this.state.profile_details.assets != null &&
      this.state.profile_details.assets !== ""
    ) {
      assets = (
        <Row className="about-row">
          <Col className="heading" style={{ paddingLeft: "6%" }}>
            Assets
          </Col>
          <Col className="display-content-about">
            :{this.state.profile_details.assets}
          </Col>
        </Row>
      );
    }
    if (
      this.state.profile_details.ofc_no !== "null" &&
      this.state.profile_details.ofc_no !== null &&
      this.state.profile_details.ofc_no !== ""
    ) {
      ofc_no = (
        <Row className="about-row">
          <Col className="heading" style={{ paddingLeft: "6%" }}>
            Office Number
          </Col>
          <Col className="display-content-about">
            :{this.state.profile_details.ofc_no}
          </Col>
        </Row>
      );
    }
    if (
      this.state.profile_details.ofc_email !== "null" &&
      this.state.profile_details.ofc_email != null &&
      this.state.profile_details.ofc_email !== ""
    ) {
      ofc_email = (
        <Row className="about-row">
          <Col className="heading" style={{ paddingLeft: "6%" }}>
            Office Email Id
          </Col>
          <Col className="display-content-about">
            :{this.state.profile_details.ofc_email}
          </Col>
        </Row>
      );
    }
    if (
      this.state.profile_details.ofc_address !== "null" &&
      this.state.profile_details.ofc_address != null &&
      this.state.profile_details.ofc_address !== ""
    ) {
      ofc_address = (
        <Row className="about-row">
          <Col className="heading" style={{ paddingLeft: "6%" }}>
            Office Address
          </Col>
          <Col className="display-content-about">
            :{this.state.profile_details.ofc_address}
          </Col>
        </Row>
      );
    }
    if (
      this.state.profile_details.dob !== "null" &&
      this.state.profile_details.dob != null &&
      this.state.profile_details.dob !== ""
    ) {
      dob = (
        <Row className="about-row">
          <Col className="heading" style={{ paddingLeft: "6%" }}>
            DOB
          </Col>
          <Col className="display-content-about">
            :
            <Moment format="DD/MM/YYYY">
              {this.state.profile_details.dob}
            </Moment>
          </Col>
        </Row>
      );
    }
    return (
      <div>
        <div className="profile-content-info-del2">
          <div className="profile-content-info-header">
            <div>
              {" "}
              <span className="card-heading">About </span>
            </div>
          </div>
          <div className="profile-content-info-body">
            <Row className="about-row">
              <Col className="heading" style={{ paddingLeft: "6%" }}>
                Phone Number
              </Col>
              <Col className="display-content-about">
                :{this.state.profile_details.mobile}
              </Col>
            </Row>
            {ofc_no}
            {emailId}
            {profession}
            {ofc_email}
            {address}
            {ofc_address}
            {gender}
            {dob}
            {age}
            {assets}
            {liabilities}
          </div>
        </div>{" "}
      </div>
    );
  }
}
export default About;
