import React, { Component } from 'react'
import './Showimage.css'

export class Showimage extends Component {
  render() {
    return (
      <div style={{borderRadius:"0px"}}>
        <img src={process.env.REACT_APP_BASE_URL+`${this.props.photo}`} className="showimage"/>
      </div>
    )
  }
}

export default Showimage
