import React, { Component } from 'react'
import './Error404.css'
import {Link} from 'react-router-dom'
class Error404 extends Component {
    render() {
        return (
            <div className='errorpage'>
                <span className='error404'>404 :</span>
                <div><span className="page">Page not found</span></div>
                <Link to='/login'><button className="loginagain" >Log In Again</button></Link>
            </div>
        )
    }
}
export default Error404
