import React, { Component } from "react";
import { Row, Col, ProgressBar } from "react-bootstrap";
import "./Profiledetails.css";
import { toast } from "react-toastify";
import Logo from "../../../containers/Images/Logo.png";
import axios from "axios";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Footer from "../../../components/Footer/Footer";
const initialstate = {
  nameError: "",
  emailError: "",
  passwordError: "",
  cnfrmpasswordError: "",
  partynameError: "",
  displaynameError: "",
  profileError: "",
  isUserAdmin: "",
  occupationError: "",
};
class Profiledetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialstate,
      selectedfile: null,
      displayimage: null,
      name: "",
      email: "",
      mobile: "",
      password: "",
      confirmpassword: "",
      partyname: "",
      privacy: "disable",
      displayname: "",
      occupation: "",
      showdocabout: false,
      percentage: 0,
      enable: false,
      passwordbar: false,
      passwordtype: "password",
      cnfrmpasswordtype: "password",
      eyesymbol1: false,
      eyesymbol2: false,
    };
    this.handlesubmit = this.handlesubmit.bind(this);
    this.submitimage = this.submitimage.bind(this);
  }
  handlename = (event) => {
    this.setState({ name: event.target.value });
  };
  componentDidMount() {
    // if(!this.props.location.state){
    //     this.props.history.push('/*')
    // }
  }
  handleemail = (event) => {
    this.setState({ email: event.target.value });
  };

  handlemobile = (event) => {
    this.setState({ mobile: event.target.value });
  };

  handlepassword = (event) => {
    this.setState({ password: event.target.value, passwordbar: true });
    if (this.state.password.length <= 1) {
      this.setState({ passwordbar: false });
    }
  };

  handleconfirmpassword = (event) => {
    this.setState({ confirmpassword: event.target.value });
  };

  handlepartyname = (event) => {
    this.setState({ partyname: event.target.value });
  };

  handleprivacy = () => {
    this.setState({
      privacy: this.state.privacy === "disable" ? "enable" : "disable",
    });
  };
  handledisplayname = (event) => {
    this.setState({ displayname: event.target.value });
  };
  handleoccupation = (event) => {
    this.setState({ occupation: event.target.value });
  };
  handleshow = () => {
    this.setState({
      showdocabout: "true",
    });
  };
  handlehide = () => {
    this.setState({
      showdocabout: false,
    });
  };
  submitimage = (event) => {
    this.setState({ displayimage: URL.createObjectURL(event.target.files[0]) });

    const fd = new FormData();
    fd.append("profile", event.target.files[0]);
    fd.append("follower_id", this.props.location.state.follower_id);

    const options = {
      onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          this.setState({ percentage: percent });
        }
      },
    };
    axios
      .post(
        process.env.REACT_APP_BASE_URL + `Follower/registration-step3/profile`,
        fd,
        options
      )
      .then((response) => {
        this.setState({ percentage: 100 }, () => {
          setTimeout(() => {
            this.setState({ percentage: 0 });
          });
        });
        if (response.statusText === "OK") {
          toast.success("Profile Photo Uploaded.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            className: "foo-bar",
          });
        }
      });
  };
  handlevalidationofcnfrmpaswd = (event) => {
    let cnfrmpasswordError;
    if (this.state.password !== event.target.value) {
      cnfrmpasswordError = "Password and confirm password must be same";
      this.setState({ cnfrmpasswordError: cnfrmpasswordError });
    } else {
      this.setState({ cnfrmpasswordError: null });
    }
  };
  validate = () => {
    let nameError;
    let emailError;
    let passwordError;
    let cnfrmpasswordError;
    let partynameError;
    let displaynameError;
    let occupationError;
    if (!this.state.name || this.state.name.length < 4) {
      nameError = "Enter full name";
      this.setState({ nameError });
      return false;
    } else if (this.state.name.match(/^[0-9]{10}$/)) {
      nameError = "Enter valid name";
      this.setState({ nameError });
      return false;
    } else if (!this.state.name.match(/^[A-Za-z]/)) {
      nameError = "Enter valid name";
      this.setState({ nameError });
      return false;
    } else {
      this.setState({ nameError: null });
    }
    if (!this.state.email.includes("@")) {
      emailError = "Enter valid email-id";
      this.setState({ emailError });
      return false;
    } else if (
      !this.state.email.includes(".com") &&
      !this.state.email.includes(".in") &&
      !this.state.email.includes(".org")
    ) {
      emailError = "Enter valid email-id";
      this.setState({ emailError });
      return false;
    } else {
      this.setState({ emailError: null });
    }
    if (!this.state.password) {
      passwordError = "Enter password";
      this.setState({ passwordError });
      return false;
    } else if (this.state.password.length < 6) {
      passwordError = "Enter strong password";
      this.setState({
        passwordError,
      });
      return false;
    } else {
      this.setState({ passwordError: null });
    }
    if (!this.state.confirmpassword) {
      cnfrmpasswordError = "Enter confirm password";
      this.setState({ cnfrmpasswordError });
      return false;
    }
    if (this.state.password !== this.state.confirmpassword) {
      cnfrmpasswordError = "password and confirm password must be same";
      this.setState({ cnfrmpasswordError });
      return false;
    } else {
      this.setState({ cnfrmpasswordError: null });
    }

    if (this.state.partyname) {
      if (this.state.partyname.length < 3) {
        partynameError = "Enter valid display name";
        this.setState({ partynameError });
        return false;
      } else {
        this.setState({ partynameError: null });
      }
    }
    if (!this.state.displayname) {
      displaynameError = "Enter display name";
      this.setState({ displaynameError });
      return false;
    } else if (this.state.displayname.length < 3) {
      displaynameError = "Enter valid display name";
      this.setState({ displaynameError });
      return false;
    } else {
      this.setState({ displaynameError: null });
    }
    if (!this.state.occupation) {
      this.setState({
        occupationError: "Enter Occupation",
      });
      return false;
    }
    return true;
  };
  handlesubmit = (event) => {
    event.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.setState(initialstate);
      axios
        .post(process.env.REACT_APP_BASE_URL + `Follower/registration-step3`, {
          fname: this.state.name,
          emailId: this.state.email,
          pwd: this.state.password,
          display_name: this.state.displayname,
          privacy: this.state.privacy,
          follower_id: this.props.location.state.follower_id,
          confrm: this.state.confirmpassword,
          occupation: this.state.occupation,
          //mobile:this.props.location.state.mobilenumber
        })
        .then((response) => {
          if (response.data.status == 200) {
            toast.success("Your Profile Updated. Redirecting...", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              className: "foo-bar",
            });
            setTimeout(() => {
              this.props.history.push("/login");
            }, 2000);
          } else {
            toast.error("Please check data you have entered", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              className: "foo-bar-error",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  handleeye1 = () => {
    this.setState({
      passwordtype:
        this.state.passwordtype === "password" ? "text" : "password",
      eyesymbol1: this.state.eyesymbol1 === false ? true : false,
    });
  };
  handleeye2 = () => {
    this.setState({
      cnfrmpasswordtype:
        this.state.cnfrmpasswordtype === "password" ? "text" : "password",
      eyesymbol2: this.state.eyesymbol2 === false ? true : false,
    });
  };
  render() {
    const { password } = this.state;
    const { percentage } = this.state;
    let showlabel;
    if (this.state.showdocabout) {
      showlabel = (
        <label className="disabletext">
          Enable to hide phone number and email-id
        </label>
      );
    }
    let eye1;
    if (this.state.eyesymbol1) {
      eye1 = <i class="fa fa-eye" aria-hidden="true"></i>;
    } else {
      eye1 = <i class="fa fa-eye-slash" aria-hidden="true"></i>;
    }
    let eye2;
    if (this.state.eyesymbol2) {
      eye2 = <i class="fa fa-eye" aria-hidden="true"></i>;
    } else {
      eye2 = <i class="fa fa-eye-slash" aria-hidden="true"></i>;
    }
    return (
      <div>
        <img src={Logo} alt="logo" className="logo" />
        <div className="bottom-line"></div>
        <div>
          <div className="header">Let's build a profile for you!</div>
          <div className="formContainer-user">
            <Row className="profile-row">
              <Col className="profile-left">
                <div className="profile">
                  {" "}
                  <label className="profile-label">Profile Photo</label>
                </div>
              </Col>
              <Col className="profile-center">
                {" "}<img
                  src={this.state.displayimage}
                  alt="profile_photo"
                  className="circle"
                />
                {/* <div className="profile-right"><input type= 'file'  className="uploadbutton" onChange={this.handleimage}/></div> */}
                <span className="fa-icon-pen-profiledetails">
                  <input
                    type="file"
                    className="profileimage-details"
                    accept="image/x-png,image/jpeg"
                    onChange={this.submitimage}
                    style={{ cursor: "pointer" }}
                  ></input>
                  <i class="fa fa-camera" aria-hidden="true"></i>
                </span>
              </Col>
              <div className="error-message-profile">
                {this.state.profileError}
              </div>
              <Col className="progressbar">
                {percentage > 0 && (
                  <ProgressBar
                    now={percentage}
                    label={`${percentage}%`}
                    className="progressbar"
                  />
                )}
              </Col>
            </Row>
            <div className="select-field">
              <label className="label-field">
                Full Name<span class="required">*</span>
              </label>
              <input
                className="text-field-other"
                placeholder="Full Name"
                maxLength="25"
                onChange={this.handlename}
                type="text"
                defaultValue={this.state.name}
              />
            </div>
            <div className="error-message">{this.state.nameError}</div>

            <div className="select-field">
              <label className="label-field">Email-id</label>
              <input
                className="text-field-other"
                placeholder="Eg: abc@xyz.com"
                maxLength="150"
                text="text"
                onChange={this.handleemail}
                defaultValue={this.state.email}
              />
            </div>
            <div className="error-message">{this.state.emailError}</div>

            <div className="select-field">
              <label className="label-field">
                Set Password<span class="required">*</span>
              </label>
              <input  
                className="text-field-other"
                placeholder="Password"
                type={this.state.passwordtype}
                onChange={this.handlepassword}
                defaultValue={this.state.password}
              />
              <div className="password-eye-profile">
                <span onClick={this.handleeye1}>{eye1}</span>
              </div>
              <div className="error-message">{this.state.passwordError}</div>

              <label className="label-field">
                Confirm Password<span class="required">*</span>
              </label>
              <input
                className="text-field-other"
                placeholder="Password"
                type={this.state.cnfrmpasswordtype}
                onChange={this.handleconfirmpassword}
                defaultValue={this.state.confirmpassword}
                onKeyUp={this.handlevalidationofcnfrmpaswd}
              />
              <div className="password-eye-profile">
                <span onClick={this.handleeye2}>{eye2}</span>
              </div>
            </div>
            <div className="error-message">{this.state.cnfrmpasswordError}</div>

            <div className="select-field" style={{ marginTop: "20px" }}>
              <label className="label-field">Privacy</label>
              <label class="switch">
                <input
                  type="checkbox"
                  onClick={this.handleprivacy}
                  className="text-field-other"
                />
                <span class="slider round" sty></span>
              </label>

              <span className="fa-icon-exclamation1">
                {" "}
                <i
                  class="fa fa-exclamation-circle"
                  aria-hidden="true"
                  onMouseOver={this.handleshow}
                  onMouseOut={this.handlehide}
                >
                  {" "}
                </i>{" "}
              </span>
              {showlabel}
            </div>
            <div className="select-field">
              <label className="label-field">
                Display Name<span class="required">*</span>
              </label>
              <input
                className="text-field-other"
                placeholder="Eg: Display Name"
                type="text"
                maxLength="25"
                onChange={this.handledisplayname}
                defaultValue={this.state.displayname}
              />
            </div>
            <div className="error-message">{this.state.displaynameError}</div>
            <div className="select-field">
              <label className="label-field">
                Occupation<span class="required">*</span>
              </label>
              <input
                className="text-field-other"
                placeholder="Eg: Job"
                type="text"
                onChange={this.handleoccupation}
                defaultValue={this.state.occupation}
              />
            </div>
            <div className="error-message">{this.state.occupationError}</div>
            <input
              type="submit"
              className="butt"
              value="Submit"
              onClick={this.handlesubmit.bind(this)}
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Profiledetails;
