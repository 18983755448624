import React, { Component } from 'react'
import './Education.css'
import '../../../containers/Leaderprofile/Leaderprofile.css'
import axios from 'axios'
import Popup from 'reactjs-popup'
import Moreeducation from './Moreeducation'
class Education extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            education:[],
            target_id:'',
            loading:false
        }
    }
    componentDidMount(){
        axios.get(process.env.REACT_APP_BASE_URL+`target/targeteducation/${this.props.follower_id}/${this.props.leader_id}` )
        .then(response => {
if(response.data.status !=201){
    this.setState({
        education:response.data.Results
       })  
    }  
            })
        .catch(function (error) {
            console.log(error);
             })
        .finally(function () {
        });
    }
    render() {
        let more
          if(this.state.education.length >= 1){
more = <div style={{textAlign:"center"}}>
<span ><Popup modal trigger={ <span> <center><button className="add_socisl_links-button target_education_more_btn">More</button></center></span>}>
{close =>( <div>
<a className="close-popup" onClick={close}>
&times;
</a> <Moreeducation close={close} education={this.state.education}/></div>)} 
</Popup></span>
    
</div>
          }
        return (                                          
            <div className="education-container" style={{marginBottom:"15px"}}>
            <div><span className="edu_text">Education</span> 
            </div>
            
            {this.state.education.map(education =>(
                <div className="edu-spacing " style={{marginBottom:"15px"}}>
             <div><span className='education_details'>{education.degree}</span>
            </div>
            <span className='education_details_degree'>{education.institution}</span>
            </div>
            ))}       
          {more}
            </div> 
        )
    }
}
export default Education
