import React, {Component} from "react";
import "./Experiance.css";
import Axios from "axios";
import Moment from "react-moment";
class Experiance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      experiance_details: []
    };
  }
  componentDidMount() {
    Axios.get(process.env.REACT_APP_BASE_URL + `target/targetexperience/${
      this.props.follower_id
    }/${
      this.props.leader_id
    }`).then((response) => {
      if (response.data.status != 201) {
        this.setState({experiance_details: response.data.Results});
      }
    });
  }
  render() {
    return (
      <div className="experiance-container">
        <span className="experiance-card-title">Leadership Experience</span>

        {
        this.state.experiance_details.map((details, index) => (
          <div className="split-cards-profile-containers">
            {
            (() => {
              if (details.position !== "undefined" && details.position !== "" && details.position !== null) {
                return (
                  <div>
                    <span className="experiance-position">
                      {
                      details.position
                    } </span>
                  </div>
                );
              }
            })()
          }


            <div className="experience_dates_div">
              {
              (() => {
                if (details.region !== "undefined" && details.region !== "" && details.region !== null) {
                  return (

                    <span className="experiance-region">
                      {
                      details.region
                    }</span>

                  );
                }
              })()
            }


              {
              (() => {
                if (details.start_date && details.start_date) {
                  return (
                    <span className="experiance-date experience_date_from">
                      From :{" "}
                      <Moment format="MMM-YYYY">
                        {
                        details.start_date
                      }</Moment>
                    </span>
                  );
                }
              })()
            }
              {
              (() => {
                if (details.end_date && details.start_date) {
                  return (
                    <span className="experiance-date">
                      To:
                      <Moment format="MMM-YYYY">
                        {
                        details.end_date
                      }</Moment>
                    </span>
                  );
                } else if (details.start_date) {
                  return (
                    <span className="experiance-date">
                      - Present {
                      details.end_date
                    } </span>
                  );
                }
              })()
            } </div>


            {/* {(() => {
              if (
                details.region !== "undefined" &&
                details.region !== "" &&
                details.region !== null
              ) {
                return (
                  <div>
                    <span className="experiance-region">{details.region}</span>
                  </div>
                );
              }
            })()} */}


            {
            (() => {
              if (details.organization !== "undefined" && details.organization !== "" && details.organization !== null) {
                return <div className="experience_organization">
                  {
                  details.organization
                }</div>;
              }
            })()
          }
            {
            (() => {
              if (details.description !== "undefined" && details.description !== "" && details.description !== null) {
                return (
                  <div>
                    <span className="description-experiance-">
                      {" "}
                      {
                      details.description
                    } </span>
                  </div>
                );
              }
            })()
          }
            <hr className="hr-tag-profile"/>{" "} </div>
        ))
      } </div>
    );
  }
}

export default Experiance;
