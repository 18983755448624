import React from 'react'
import Loader from 'react-loader-spinner'
import './Reactloader.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

 export default class Reactloader extends React.Component {
    render() {
     return(
       <div className="spinner">
      <Loader
         type="Bars"
         height={30}
         color="#1de8b4"
      /></div> );
    }
 }
