import React, { Component } from 'react'
import '../../../containers/Leaderprofile/Leaderprofile.css'
import Axios from 'axios'
import {withRouter} from 'react-router'
 class Activity extends Component {
    state={
        activity_details:''
    }
pushtoproblem = () =>{
    this.props.history.push({
        pathname:'/targetprofile/myactivity/problems',
        state:{
            id:this.props.leader_id,
            sid:this.props.s_id
        }
    });
}
pushtoproposals = () =>{
    this.props.history.push({
        pathname:'/targetprofile/myactivity/proposals',
        state:{
            id:this.props.leader_id,
            sid:this.props.s_id
        }
    });
}
pushtopost = () =>{
    this.props.history.push({
        pathname:'/targetprofile/myactivity/posts',
        state:{
            id:this.props.leader_id,
            sid:this.props.s_id
        }
    });
}
pushtoproblemtaged=() =>{
    this.props.history.push({
        pathname:'/targetprofile/myactivity/problemtaged',
        state:{
            id:this.props.leader_id,
            sid:this.props.s_id
        }
    });
}
    componentDidMount(){
        Axios.get(process.env.REACT_APP_BASE_URL+`target/targetactivitylist/${this.props.s_id}/${this.props.leader_id}`)
        .then(response =>{
            this.setState({
                activity_details:response.data
            })
        })
    }
    render() {
        return (
            <div>
                  <div className="profile-content-info-del1">

<div className="profile-content-info-header">
    
    <span className="card-heading">Activity</span> 
</div>
<div className="profile-content-info-body">
    <table>
        <tbody>
            <tr>
            <td className="heading" onClick={this.pushtoproblem} style={{cursor:"pointer"}}>Problems</td>
                <td className="display-content" onClick={this.pushtoproblem} style={{cursor:"pointer"}}>: {this.state.activity_details.Problems}</td>
                <td className="display-content"></td>
            </tr>
            <tr>
             <td className="heading" onClick={this.pushtoproposals} style={{cursor:"pointer"}}>Proposals </td>
                <td className="display-content" onClick={this.pushtoproposals} style={{cursor:"pointer"}}>: {this.state.activity_details.Proposals}</td>
                <td className="display-content"> </td>
            </tr>
            
            <tr>
            <td className="heading" onClick={this.pushtopost} style={{cursor:"pointer"}}> Posts</td>
                <td className="display-content" onClick={this.pushtopost} style={{cursor:"pointer"}}>: {this.state.activity_details.NumberofPosts}</td>
                <td className="display-content"></td>
            </tr>
             <tr>
          <td className="heading" onClick = {this.pushtoproblemtaged}  style={{cursor:"pointer"}}>Problems Tagged</td>
                <td className="display-content" onClick = {this.pushtoproblemtaged}  style={{cursor:"pointer"}} >: {this.state.activity_details.ProblemsTagged}</td>
                
            </tr> 
        </tbody>
    </table>
    
</div>
</div>
            </div>
        )
    }
}
export default withRouter(Activity)
