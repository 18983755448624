import React, { Component } from 'react'
import Home from './containers/Home/Home'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer} from 'react-toastify';
import './App.css'
export class App extends Component {
  render() {
    return (
      <div className="App">
        <ToastContainer />
    <Home />
      </div>
    )
  }
}

export default App


