import React, { Component , Suspense} from 'react'
import './Home.css'
// import Login from '../Login/Login'
import { Switch , Route, BrowserRouter as Router, Redirect} from 'react-router-dom'
// import Leadersfeed from '../LeadersFeed/Leadersfeed'
import Reactloader from '../../components/Loader/Reactloader'
// import Mobile from '../Signup/Mobile/Mobile'
import Verify from '../../containers/Signup/Verify/Verify'
import Userdetails from '../../containers/Signup/Userdetails/Userdetails'
import Profiledetails from '../../containers/Signup/Profiledetails/Profiledetails'
import Startpage from '../Login/Startpage/Startpage'
import Recover from '../../containers/Login/Recover/Recover'
import Recoverhome from '../Login/Recover/Recoverhome'
//import LeaderProfile from '../../containers/Leaderprofile/Leaderprofile'
import Error404 from '../../components/Error404/Error404'
import Targetprofile from '../Targetprofile/Targetprofile'
// import Signup from '../Signup/Signup'
const Mobile = React.lazy(() => import('../Signup/Mobile/Mobile'));
const Leadersfeed = React.lazy(()=>import('../Leaderfeed/Leaderfeed'))
const Leadersprofile = React.lazy(()=>import('../../containers/Leaderprofile/Leadersprofile'))



class Home extends Component {
    render() {
        return (
            <div>
               
                <Router>
                    <Switch>
                        <Redirect from ='/' to ='/login' exact/>

                        <Route path='/login' render={() => (
                    <Suspense fallback={<Reactloader />}>
                        <Startpage />
                    </Suspense>
                )} />
                
                <Route path="/recover" render={(props) => (
                    <Suspense fallback={<Reactloader />}>
                        <Recover {...props} />
                    </Suspense>
                )} />
                        <Route path="/signup" render={() => (
                    <Suspense fallback={<Reactloader />}>
                        <Mobile />
                    </Suspense>
                )} />

<Route path="/confirmrecover" render={(props) => (
                    <Suspense fallback={<Reactloader />}>
                        <Recoverhome {...props} />
                    </Suspense>
                )} />
                
<Route path='/verify' render={(props) => (
                         <Suspense fallback={<Reactloader />}>
                              <Verify {...props} />
                             </Suspense>
)}/>
<Route path='/userdetails' render={(props) => (
                         <Suspense fallback={<Reactloader />}>
                              <Userdetails {...props} />
                             </Suspense>
)}/>
<Route path='/profiledetails' render={(props) => (
                         <Suspense fallback={<Reactloader />}>
                              <Profiledetails {...props} />
                             </Suspense>
)}/>
                       

                         <Route path='/feed' render={() => (
                         <Suspense fallback={<Reactloader />}>
                              <Leadersfeed />
                             </Suspense>
                )} /> 

<Route path='/profile' render={() => (
                         <Suspense fallback={<Reactloader />}>
                              <Leadersprofile />
                             </Suspense>
                )} /> 

<Route path='/targetprofile' render={(props) => (
                         <Suspense fallback={<Reactloader />}>
                              <Targetprofile {...props}/>
                             </Suspense>
                )} /> 
                 
                        {/* <Route path='/profile' component={LeaderProfile}/> */}
                    <Route path="*" component={Error404} />
</Switch>
                </Router>

                        {/* <Route path = '/home/login' component = {Login} /> */}
                        {/* <Route path = '/home/signup' component = {Signup}/> */}
                        {/* <Route path = '/feed' component = {Leadersfeed}/> */}
                        {/* <Route path = '/home/verify' component = {Verify}/> */}
                 
            </div>
        )
    }
}

export default Home
