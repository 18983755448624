import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "./Startpage.css";
import { Container, Row, Col } from "react-bootstrap";
import { Fade } from "react-slideshow-image";
import Logo from "../../../containers/Images/Logo.png";
import one from "../../../containers/Images/one.png";
import two from "../../../containers/Images/two.png";
import three from "../../../containers/Images/three.png";
import four from "../../../containers/Images/four.png";
import axios from "axios";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer/Footer";
const initialstate = {
  mobileError: "",
  passwordError: "",
  error: "",
  pswError: "",
};
const fadeProperties = {
  duration: 3000,
  transitionDuration: 1000,
  infinite: true,
  indicators: true,
  arrows: false,
};
class Startpage extends Component {
  state = {
    initialstate,
    mobile: "",
    password: 0,
    follower_id: "",
    user: "",
    showsubmitbutton: true,
    eyesymbol: false,
    type: "password",
  };
  componentDidMount() {
    this.userData = JSON.parse(localStorage.getItem("user"));
    if (localStorage.getItem("user")) {
      this.setState({
        follower_id: this.userData.follower_id,
      });
    } else {
      this.setState({
        follower_id: "",
      });
    }
  }
  componentWillUpdate(nextProps, nextState) {
    localStorage.setItem("user", JSON.stringify(nextState));
  }
  handleforgot = () => {
    this.props.history.push({
      pathname: "/recover",
    });
  };
  handleeye = () => {
    this.setState({
      type: this.state.type === "password" ? "text" : "password",
      eyesymbol: this.state.eyesymbol === false ? true : false,
    });
  };
  validate = () => {
    let mobileError;
    let passwordError;
    if (!this.state.mobile) {
      mobileError = "Enter phone number";
      this.setState({ mobileError, error: "error" });
      return false;
    } else if (!this.state.mobile.match(/^[0-9]{10}$/)) {
      mobileError = "Enter valid phone number";
      this.setState({ mobileError, error: "error" });
      return false;
    } else {
      this.setState({ mobileError: null, error: "" });
    }
    if (!this.state.password) {
      passwordError = "Enter password";
      this.setState({ passwordError, pswError: "error" });
      return false;
    } else if (this.state.password.length < 6) {
      passwordError = "Enter valid password";
      this.setState({ passwordError, pswError: "error" });
      return false;
    } else {
      this.setState({ passwordError: null, pswError: "" });
    }
    return true;
  };
  handlemobile = (event) => {
    let mob = event.target.value;
    if (mob.length <= 10 && mob.match(/^[0-9]/)) {
      this.setState({ mobile: mob });
    } else if (mob.length < 1) {
      this.setState({ mobile: "" });
    }
  };
  handlepassword = (event) => {
    this.setState({ password: event.target.value });
  };
  pushtologin = () => {
    this.props.history.push("/signup");
  };
  handlelogin = () => {
    const isValid = this.validate();
    if (isValid) {
      this.setState({ showsubmitbutton: false });
      this.setState({ initialstate });
      axios
        .post(process.env.REACT_APP_BASE_URL + `Follower/login`, {
          mobile: this.state.mobile,
          pwd: this.state.password,
        })
        .then((response) => {
          if (response.data.status === 200) {
            this.setState({
              follower_id: response.data.follower_id,
            });
            toast.success("Login Successfull. Redirecting...", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              className: "foo-bar",
            });
            setTimeout(() => {
              this.props.history.push({
                pathname: "/feed",
                state: {
                  follower_id: response.data.follower_id,
                },
              });
            }, 1000);
          } else if (response.data.status === 403) {
            toast.error("Unregistered Phone Number", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              className: "foo-bar-error",
            });
            setTimeout(() => {
              this.setState({ showsubmitbutton: true });
              this.props.history.push("/signup");
            }, 3000);
          } else if (response.data.status === 407) {
            toast.error("Incorrect Username or Password", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              className: "foo-bar-error",
            });
            setTimeout(() => {
              this.setState({ showsubmitbutton: true });
            }, 3000);
          } else if (response.data.status === 500) {
            toast.error("Internal server error", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              className: "foo-bar-error",
            });
            this.setState({ showsubmitbutton: true });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      setTimeout(() => {
        this.setState({ showsubmitbutton: true });
      }, 5000);
    }
  };
  render() {
    let eye;
    if (this.state.eyesymbol) {
      eye = <i class="fa fa-eye" aria-hidden="true"></i>;
    } else {
      eye = <i class="fa fa-eye-slash" aria-hidden="true"></i>;
    }
    let mobilevalue = this.state.mobile;
    let showbutton;
    if (this.state.showsubmitbutton) {
      showbutton = (
        <input
          type="button"
          value="Log In"
          className="formButtons-personal"
          onClick={this.handlelogin}
        />
      );
    } else {
      showbutton = (
        <input
          type="button"
          value="Log In"
          className="formButtons-personal"
          disabled
          onClick={this.handlelogin}
        />
      );
    }
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <a href="https://www.myleaders.in"><img className="logo-image" src={Logo}  alt="logo" /></a>
            </Col>
            <Col>
              <button
                className="signup-button-recover"
                onClick={() => {
                  this.props.history.push("/signup");
                }}
              >
                Sign-Up
              </button>
            </Col>
          </Row>
        </Container>
        <div className="menuhr" />
        <Container>
          <Row>
            <Col className="left">
              <Fade {...fadeProperties}>
                <div className="each-fade">
                  <div className="image-container">
                    <img src={one} alt="sliderimage" />
                    <div></div>{" "}
                    <span className="first-text">
                      Stay Connected With Leader You Admire.
                    </span>
                  </div>
                </div>
                <div className="each-fade">
                  <div className="image-container">
                    <img src={two} alt="sliderimage" />
                    <div>
                      <span className="second-text">
                        Share Your Opinion On leaders Proposal.
                      </span>
                    </div>
                  </div>
                </div>

                <div className="each-fade">
                  <div className="image-container">
                    <img src={three} alt="sliderimage" />
                    <div>
                      <span className="third-text">
                        Stay up to date on issues around you.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="each-fade">
                  <div className="image-container">
                    <img src={four} alt="sliderimage" />
                    <div>
                      <span className="fourth-text">
                        Share Problems Around You And Get Solution
                      </span>
                      <br />
                      <span className="fourth-text-break">
                        {" "}
                        From Concerned Leader.
                      </span>{" "}
                    </div>
                  </div>
                </div>
              </Fade>
            </Col>
            <Col className="right right-form">
              <form className="formContainer-personal">
                <p className="signtext">Login</p>
                <input
                  className="input-field-login"
                  type="number"
                  placeholder=" Enter Phone Number"
                  onChange={this.handlemobile}
                  value={mobilevalue}
                  id={this.state.error}
                />
                <div className="error-message-login">
                  {this.state.mobileError}
                </div>
                <input
                  className="input-field-login"
                  type={this.state.type}
                  name="SetPassword"
                  placeholder=" Password"
                  MaxLength="64"
                  onChange={this.handlepassword}
                  id={this.state.pswError}
                />
                <div className="password-eye">
                  <span onClick={this.handleeye}>{eye}</span>
                </div>
                <div className="error-message-login">
                  {this.state.passwordError}
                </div>
                <h1
                  onClick={this.handleforgot}
                  style={{ cursor: "pointer" }}
                  style={{ color: "#1de8b4", fontWeight: "600" }}
                >
                  Forgot password ?{" "}
                </h1>
                {showbutton}
                <div>
                  {" "}
                  <span className="buttom-text">
                    I am new to myleaders &nbsp;.
                  </span>{" "}
                  <span className="user-text-login" onClick={this.pushtologin}>
                    Sign Up
                  </span>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}
export default withRouter(Startpage);
