import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Forgotimg from "../../../containers/Images/forgot.png";
import Logo from "../../../containers/Images/Logo.png";
import Axios from "axios";
import { toast } from "react-toastify";
import "./Recover.css";
import Footer from "../../../components/Footer/Footer";
import { Link } from "react-router-dom";
const initialstate = {
  mobileError: "",
};
class Recover extends Component {
  state = {
    initialstate,
    mobile: "",
  };
  handlchange = (event) => {
    this.setState({ mobile: event.target.value });
  };
  validate = () => {
    let mobileError = "";
    if (!this.state.mobile) {
      mobileError = "Enter mobile number";
    }
    if (mobileError) {
      this.setState({ mobileError });
      return false;
    }
    if (typeof this.state.mobile !== "undefined") {
      if (!this.state.mobile.match(/^[0-9]{10}$/)) {
        mobileError = "Enter valid mobile number";
      }
    }
    if (mobileError) {
      this.setState({ mobileError });
      return false;
    }
    return true;
  };
  handlesumit = (event) => {
    event.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.setState(initialstate);
      Axios.post(process.env.REACT_APP_BASE_URL + `Follower/followersignin`, {
        mobile: this.state.mobile,
      }).then((response) => {
        if (response.data.status === 200) {
          toast.success("OTP sent succssfully!!!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            className: "foo-bar",
          });
          setTimeout(() => {
            this.props.history.push({
              pathname: "/confirmrecover",
              state: {
                mobilenumber: this.state.mobile,
              },
            });
          }, 1000);
        } else if (response.data.status === 405) {
          toast.error("Incomplete Registration", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            className: "foo-bar",
          });
          setTimeout(() => {
            this.props.history.push({
              pathname: "/signup",
            });
          }, 2000);
        } else if (response.data.status === 201) {
          toast.error("Unregistered Mobile Number", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            className: "foo-bar",
          });
          setTimeout(() => {
            this.props.history.push({
              pathname: "/signup",
            });
          }, 2000);
        } else {
          toast.error("Server error", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            className: "foo-bar-error",
          });
        }
      });
    }
  };
  render() {
    return (
      <div>
        {" "}
        <container-fluid>
          <Row>
            <Col>  <a href="https://www.myleaders.in">
              <img className="logo-image" src={Logo} alt="logo" /></a>
            </Col>
            <Col>
              <button
                className="signup-button-recover"
                onClick={() => {
                  this.props.history.push("/signup");
                }}
              >
                Sign-Up
              </button>
            </Col>
          </Row>{" "}
          <div className="menuhr" />
          <Row>
            <Col className="left">
              {" "}
              <img
                className="forgot-left-image"
                src={Forgotimg}
                alt="recover"
              />
            </Col>
            <Col className="right">
              <div className="formContainer-others">
                <p className="forgot-text">Did you forget your password ?</p>
                <p className="pass">Enter your registered mobile number</p>
                <input
                  className="input-field-recover"
                  type="text"
                  placeholder="Phone Number"
                  onChange={this.handlchange}
                />
                <div className="validation-error-messge">
                  {this.state.mobileError}
                </div>
                <button
                  className="formButtons"
                  type="submit"
                  name="signup_submit"
                  onClick={this.handlesumit}
                >
                  RECOVER PASSWORD
                </button>
                <div ClassName="card-buttom-text">
                  {" "}
                  <Link to="/login">
                    <span className="par">I am new to myleaders &nbsp;</span>
                    <span
                      className="joinnow-recover"
                      style={{ cursor: "pointer" }}
                    >
                      Log In
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </container-fluid>
        <Footer />
      </div>
    );
  }
}
export default Recover;
